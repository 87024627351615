import * as THREE from "three"
import React, {useState, useRef, Suspense } from "react";
import {
    Environment,
    OrbitControls,
    TransformControls,
    softShadows,
    ContactShadows,
    Effects } from "@react-three/drei";
import { Canvas, useFrame }   from "react-three-fiber";
import {proxy, useProxy} from "valtio";
import "./App.css";

import { useSpring, a } from "react-spring/three";

//import Applicase from "./models/Applicase";
import Model from "./models/11922_AMG_Stossfaenger";

import Box from "./models/Box";
import Ground from "./models/Ground_2K";
import Picker from "./components/Picker";

softShadows({
  frustrum: 3.75, // Frustum width (default: 3.75) must be a float
  size: 0.005, // World size (default: 0.005) must be a float
  near: 9.5, // Near plane (default: 9.5) must be a float
  samples: 17, // Samples (default: 17) must be a int
  rings: 11, // Rings (default: 11) must be a int
});

const state = proxy({
  current: null,
  items: {
    body: "#e4aa16",
  },
});

const App = () => {
  const [withCorners, setWithCorners] = React.useState(true);
  const [withFeet, setWithFeet] = React.useState(true);
  const [withHandle, setWithHandle] = React.useState(true);
  const [pickerActive, setPickerActive] = React.useState(false);

  return (
    <>
      <Canvas
        shadowMap
        pixelRatio={[1, 2]}
        style={{ width: "100%", height: "100%" }}
        camera={{
          zoom: 7,
          position: [0, 0, 2.75],
          fov: 110,
        }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.ACESFilmicToneMapping;
        }}
      >
        <color attach="background" args={["#111111"]} />

        <Suspense fallback={null}>
          <Model />
          <Environment files="WIS_HDRI.hdr" />
          <Ground position={[0, -0.501, 0]} />
        </Suspense>
        <OrbitControls
          autoRotateSpeed={0.4}
          autoRotate={true}
          minZoom={2.0}
          maxZoom={2.0}
          minDistance={1}
          maxDistance={5}
          minPolarAngle={-1}
          maxPolarAngle={1.6}
        />
        <ContactShadows />
      </Canvas>
    </>
  );
};

export default App;
