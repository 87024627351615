/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/11922_AMG_Stossfaenger.glb')
  return (
    <group ref={group} {...props} dispose={null}
      scale={[.5,.5,.5]}
      rotation={[0,45,0]}
      position={[0,-.5,0]}
      >
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger002_1"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger002_2"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger604"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger602"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger602_1"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger593"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger592"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-paint-gray"]}
        geometry={nodes["11922-AMG-Stossfaenger573"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger568"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-paint-gray"]}
        geometry={nodes["11922-AMG-Stossfaenger552"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger530"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger528"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger458"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger457"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-aluminium"]}
        geometry={nodes["11922-AMG-Stossfaenger413"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger336"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger319"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_m-iron"]}
        geometry={nodes["11922-AMG-Stossfaenger257"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-rubber"]}
        geometry={nodes["11922-AMG-Stossfaenger246"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-paint-green"]}
        geometry={nodes["11922-AMG-Stossfaenger003"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-paint-green"]}
        geometry={nodes["11922-AMG-Stossfaenger002"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        material={materials["WIS_d-paint-rose"]}
        geometry={nodes["11922-AMG-Stossfaenger001"].geometry}
      />
    </group>
  );
}

useGLTF.preload('/11922_AMG_Stossfaenger.glb')
